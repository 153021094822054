import ItemColumn from "./ItemColumn";
import ProtagonistPart from "./ProtagonistPart";

function CenterPart() {
    return (
        <div className="center-part">
            <ItemColumn
                itemsImg={[{"backgroundImage": "placeholders/shokkoth.png"}, {"backgroundImage": "placeholders/coiffe-du-comte-harebourg.png"},
                    {"backgroundImage": "placeholders/shokkoth.png"}, {"backgroundImage": "placeholders/coiffe-du-comte-harebourg.png"},
                    {"backgroundImage": "placeholders/shokkoth.png"}, {"backgroundImage": "placeholders/coiffe-du-comte-harebourg.png"},]}/>

            <ItemColumn
                itemsImg={[{"backgroundImage": "placeholders/shokkoth.png"}, {"backgroundImage": "placeholders/shokkoth.png"},
                    {"backgroundImage": "placeholders/coiffe-du-comte-harebourg.png"},]}/>

            <ProtagonistPart/>

            <ItemColumn
                itemsImg={[{"backgroundImage": "placeholders/shokkoth.png"}, {"backgroundImage": "placeholders/shokkoth.png"},
                    {"backgroundImage": "placeholders/coiffe-du-comte-harebourg.png"},]}/>

            <ItemColumn itemsImg={[{"backgroundImage": "placeholders/shokkoth.png"},
                {"backgroundImage": "placeholders/shokkoth.png"}, {"backgroundImage": "placeholders/coiffe-du-comte-harebourg.png"},
                {"backgroundImage": "placeholders/shokkoth.png"}, {"backgroundImage": "placeholders/coiffe-du-comte-harebourg.png"},]}/>
        </div>
    );
}

export default CenterPart;