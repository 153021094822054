import React from "react";
import "../resources/style/main.css"
import logo from "../resources/images/logo/logo_navbar.png"


function Navbar() {
    return (
        <nav className="nav">
            <ul className="nav-menu">
                <li className="nav-item">
                    Roxx-Builder
                </li>
                <li className="nav-item">
                    <img src={logo} alt="Logo" className="nav-logo"/>
                </li>
                <li className="nav-item">
                    About
                </li>
            </ul>
        </nav>
    )
}

export default Navbar;