import './App.css';
import MainPage from "./components/MainPage";
// import TestPage from "./tests/TestPage";

function App() {
  return (
    <div className="App">
        <MainPage/>
        {/*<TestPage/>*/}
    </div>
  );
}

export default App;
