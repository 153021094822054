import React from "react";
import Navbar from "./Navbar";
import LeftPart from "./LeftPart";
import RightPart from "./RightPart";
import CenterPart from "./CenterPart";
import "../resources/style/main.css"

function MainPage() {
    return (
        <div className="main-page">
            <div className="nav-container">
                <Navbar/>
            </div>
            <div className="core-part">
                <LeftPart/>
                <CenterPart/>
                <RightPart/>
            </div>
        </div>
    )
}

export default MainPage;