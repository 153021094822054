import React from 'react'
import ItemSlot from "./ItemSlot";
import PropTypes from "prop-types";

function ItemColumn(props) {
    return (
        <div className="item-column">
            <ul>
                {props.itemsImg.map((elem, index) => (
                    <li key={index}>
                        <ItemSlot backgroundImage={elem.backgroundImage}/>
                    </li>
                ))}
            </ul>
        </div>

    )
}

ItemColumn.prototype = {
    itemsImg: PropTypes.array.isRequired,
}

export default ItemColumn;
