function LeftPart() {
    return (
        <div className="side-part">
            <p>
                Left
            </p>
        </div>
    );
}

export default LeftPart;