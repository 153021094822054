import PropTypes from "prop-types";

const imgPath = "resources/images/items/";

function ItemSlot(props) {
    var fullPath = imgPath + props.backgroundImage;
    return (
        <div className="item-slot">
            <img src={fullPath} alt="Item slot"/>
        </div>
    );
}

ItemSlot.prototype = {
    backgroundImage : PropTypes.string.isRequired,
}

export default ItemSlot;